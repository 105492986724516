<template>
  <div class>
    <v-row>
      <v-col cols="12" md="11" class="title-col">
        <div class="title">
          <h1>{{ $t("Push Notification") }}</h1>
        </div>
      </v-col>
      <v-col cols="12" md="1">
        <v-icon
          class="main-color"
          @click="edit"
          v-can="'edit-push-notifications'"
          >edit</v-icon
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" sm="12" lg="4"
        ><v-card class="card">
          <v-row>
            <v-col sm="12">
              <label>{{ $t("Subject") }}</label>
              <p>{{ data.subject }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <label>{{ $t("Message") }}</label>
              <p>{{ data.message }}</p>
            </v-col> </v-row
          ><v-row>
            <v-col sm="12">
              <label>{{ $t("Link") }}</label>
              <br />
              <a :href="data.link">{{ data.link }}</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <label>{{ $t("Platform") }}</label>
              <p>{{ data.platforms }}</p>
            </v-col>
          </v-row></v-card
        ></v-col
      >
      <v-col cols="12" md="4" sm="12" lg="4"
        ><v-card class="card">
          <div max-height="100">
            <v-row>
              <v-col sm="12">
                <label>{{ $t("Time") }}</label>
                <v-row>
                  <v-col sm="6" md="3" v-if="data.time_type == 'now'">
                    <v-btn
                      class="custom-auto"
                      :disabled="data.time_type == 'now' ? true : false"
                    >
                      <v-img
                        src="../../../assets/icons/now-01.png"
                        width="20"
                        height="35"
                      ></v-img>
                    </v-btn>

                    <label class="transparent_label custom-auto-lable">{{
                      $t("Now")
                    }}</label>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.time_type == 'schedule'">
                    <v-btn
                      :disabled="data.time_type == 'schedule' ? true : false"
                    >
                      <v-img
                        src="../../../assets/icons/schedule-01.png"
                        width="20"
                        height="35"
                      ></v-img>
                    </v-btn>
                    <label class="transparent_label">{{
                      $t("Schedule")
                    }}</label>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-show="data.time_type == 'schedule'">
            <v-row>
              <v-col sm="12">
                <label>{{ $t("Date/Time Start") }}</label>
                <p>{{ data.start_date }}</p>
              </v-col> </v-row
            ><v-row>
              <v-col sm="12">
                <label>{{ $t("Repeat") }}</label>
                <v-row>
                  <v-col sm="6" md="3" v-if="data.repeat == 'once'">
                    <v-btn
                      class="transparent"
                      :disabled="data.repeat == 'once' ? true : false"
                      @click="ChooseRepeat('once')"
                    >
                      <v-img
                        src="../../../assets/icons/once-01.png"
                        width="20"
                        height="35"
                      ></v-img>
                    </v-btn>
                    <label class="transparent_label">{{ $t("Once") }}</label>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.repeat == 'daily'">
                    <v-btn
                      class="transparent"
                      @click="ChooseRepeat('daily')"
                      :disabled="data.repeat == 'daily' ? true : false"
                    >
                      <v-img
                        src="../../../assets/icons/daily-01.png"
                        width="20"
                        height="35"
                      ></v-img>
                    </v-btn>
                    <label class="transparent_label">{{ $t("Daily") }}</label>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.repeat == 'weekly'">
                    <v-btn
                      class="transparent"
                      @click="ChooseRepeat('weekly')"
                      :disabled="data.repeat == 'weekly' ? true : false"
                    >
                      <v-img
                        src="../../../assets/icons/weekly-01.png"
                        width="20"
                        height="35"
                      ></v-img>
                    </v-btn>
                    <label class="transparent_label">{{ $t("Weekly") }}</label>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.repeat == 'monthly'">
                    <v-btn
                      class="transparent"
                      @click="ChooseRepeat('monthly')"
                      :disabled="data.repeat == 'monthly' ? true : false"
                    >
                      <v-img
                        src="../../../assets/icons/monthly-01.png"
                        width="20"
                        height="35"
                      ></v-img>
                    </v-btn>
                    <label class="transparent_label">{{ $t("Monthly") }}</label>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.repeat == 'yearly'">
                    <v-btn
                      class="transparent"
                      @click="ChooseRepeat('yearly')"
                      :disabled="data.repeat == 'yearly' ? true : false"
                    >
                      <v-img
                        src="../../../assets/icons/yearrly-01.png"
                        width="20"
                        height="35"
                      ></v-img>
                    </v-btn>
                    <label class="transparent_label">{{ $t("Yearly") }}</label>
                  </v-col>
                </v-row>
                <label
                  v-if="data.time_type == 'schedule' && data.repeat == ''"
                  class="label_error"
                  >{{ $t("This field is required") }}</label
                >
              </v-col>
            </v-row>
            <div v-show="data.repeat != 'once'">
              <v-row>
                <v-col sm="12">
                  <label>{{ $t("Date/Time End") }}</label>
                  <p>{{ data.end_date }}</p>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card></v-col
      >
      <v-col cols="12" md="4" sm="12" lg="4"
        ><v-card class="card">
          <div max-height="300" v-if="data.roles.length > 0">
            <v-row>
              <v-col sm="12">
                <label>{{ $t("Audience") }}</label>
                <v-row>
                  <v-col sm="6" md="3" v-if="data.roles.includes('1')">
                    <div class="ChangeRole">
                      <div class="radioGroupContainer">
                        <v-checkbox value="1">
                          <template slot="label">
                            <div>
                              <v-img
                                class="margin_left_image"
                                :class="
                                  data.roles.includes('1') ? 'active' : ''
                                "
                                src="../../../assets/images/Super-Admin.png"
                              ></v-img>
                            </div>
                            <label class="roleLabelCheck" for>{{
                              $t("Super-admin")
                            }}</label>
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.roles.includes('6')">
                    <div class="ChangeRole">
                      <div class="radioGroupContainer">
                        <v-checkbox value="6">
                          <template slot="label">
                            <div>
                              <v-img
                                class="margin_left_image"
                                :class="
                                  data.roles.includes('6') ? 'active' : ''
                                "
                                src="../../../assets/images/Accountant.png"
                              ></v-img>
                            </div>
                            <label class="roleLabelCheck" for>{{
                              $t("Accountant")
                            }}</label>
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.roles.includes('2')">
                    <div class="ChangeRole">
                      <div class="radioGroupContainer">
                        <v-checkbox value="2">
                          <template slot="label">
                            <div>
                              <v-img
                                class="margin_left_image"
                                :class="
                                  data.roles.includes('2') ? 'active' : ''
                                "
                                src="../../../assets/images/Coordinator.png"
                              ></v-img>
                            </div>
                            <label class="roleLabelCheck" for>{{
                              $t("Coordinator")
                            }}</label>
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.roles.includes('3')">
                    <div class="ChangeRole">
                      <div class="radioGroupContainer">
                        <v-checkbox value="3">
                          <template slot="label">
                            <div>
                              <v-img
                                class="margin_left_image"
                                :class="
                                  data.roles.includes('3') ? 'active' : ''
                                "
                                src="../../../assets/images/Teacher.png"
                              ></v-img>
                            </div>
                            <label class="roleLabelCheck" for>{{
                              $t("Teacher")
                            }}</label>
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.roles.includes('5')">
                    <div class="ChangeRole">
                      <div class="radioGroupContainer">
                        <v-checkbox value="5">
                          <template slot="label">
                            <div>
                              <v-img
                                class="margin_left_image"
                                :class="
                                  data.roles.includes('5') ? 'active' : ''
                                "
                                src="../../../assets/images/Parent.png"
                              ></v-img>
                            </div>
                            <label class="roleLabelCheck" for>{{
                              $t("Parent")
                            }}</label>
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="6" md="3" v-if="data.roles.includes('4')">
                    <div class="ChangeRole">
                      <div class="radioGroupContainer">
                        <v-checkbox value="4">
                          <template slot="label">
                            <div>
                              <v-img
                                class="margin_left_image"
                                :class="
                                  data.roles.includes('4') ? 'active' : ''
                                "
                                src="../../../assets/images/Student.png"
                              ></v-img>
                            </div>
                            <label class="roleLabelCheck" for>{{
                              $t("Student")
                            }}</label>
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-row v-if="data.nationalites_names.length > 0">
            <v-col sm="12">
              <label>{{ $t("Nationality") }}</label>

              <v-chip-group column active-class="blue--text">
                <div
                  v-for="(nationality, index) in data.nationalites_names"
                  :key="index"
                >
                  <v-chip class="ma-2" color="#EEF3FF" style="color: #7297ff">{{
                    nationality.name
                  }}</v-chip>
                </div>
              </v-chip-group>
            </v-col>
          </v-row></v-card
        ></v-col
      >
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ViewPushNotification",
  data() {
    return {
      data: {
        subject: "",
        message: "",
        link: "",
        platforms: [],
        time_type: "",
        start_date: "",
        end_date: "",
        repeat: "",
        roles: [],
        nationalites_names: []
      },
      id: ""
    };
  },
  methods: {
    edit() {
      this.$router.push(
        {
          path: "/editPushNotification/" + this.id
        },
        () => {}
      );
    },
    GetPushNotification() {
      // this.data.start_date = new Date(this.data.start_date);
      // this.data.end_date = new Date(this.data.end_date);
      axios
        .get(
          this.getApiUrl + "/push_notifications/show/" + this.id,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            this.data = response.data.data;
          }
        });
    }
  },
  mounted() {
    this.id = this.$router.currentRoute.params.id;
    this.GetPushNotification();
  }
};
</script>
<style scoped lang="scss">
@import "../../../styles/_variables.scss";
@import "../../../styles/_modals.scss";

label {
  color: #8d8a8a;
  font-size: 13px;
  font-weight: bold;
}
.label_error {
  font-size: 14px !important;
  font-weight: normal;
  color: #ff5252 !important;
  margin-left: 20px;
  margin-top: -20px;
}
// .card {
//   height: 670px;
// }
.buttons {
  text-align: right;
}
.paddingClass {
  margin: 3px;
}
.transparent {
  background-color: transparent;
}
.transparent_label {
  color: $main-color;
  font-size: 10px;
  text-align: center !important;
}
.time_type_active {
  background-color: #b9cbff;
}
.custom-auto,
.custom-auto-lable {
  display: block !important;
  margin: auto !important;
}
</style>
